<template>
  <div class="profile">
    <van-cell-group>
      <van-cell title="头像" class="cell-avatar" center>
        <template #right-icon>
          <img :src="User.icon | userIconFix" class="cell-right" />
          <van-uploader :after-read="uploadImg" accept="image/*" />
          <van-icon name="arrow" class="van-cell__right-icon" />
        </template>
      </van-cell>

      <van-cell
        title="昵称"
        :value="User.username"
        is-link
        @click="nicknamPopupShow = true"
      />

      <van-cell title="性别" is-link @click="genderPopupShow = true">
        <template #default>
          <span v-if="User.gender === 1">男</span>
          <span v-else-if="User.gender === 2">女</span>
          <span v-else>保密</span>
        </template>
      </van-cell>

      <van-cell
        title="生日"
        :value="User.birthday | formatDate('ymdcn')"
        is-link
        @click="birthdayPopupShow = true"
      />
    </van-cell-group>

    <!--性别-->
    <van-popup v-model="genderPopupShow" position="bottom">
      <van-picker
        show-toolbar
        :columns="actions"
        :default-index="actions.value"
        @confirm="modifyGender"
        @cancel="genderPopupShow = false"
      />
    </van-popup>
    <!--生日-->
    <van-popup v-model="birthdayPopupShow" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="modifyBirthday"
        @cancel="birthdayPopupShow = false"
      />
    </van-popup>
    <!--昵称-->
    <van-dialog
      v-model="nicknamPopupShow"
      title="修改昵称"
      @confirm="modifyNickname"
      :close-on-click-overlay="true"
      show-cancel-button
    >
      <div class="edit-mod">
        <van-field v-model="newName" ref="nameinp" placeholder="请输入新昵称" />
      </div>
    </van-dialog>
  </div>
</template>

<script>
export default {
  name: "Profile",
  //inject: ["reload"],
  data() {
    return {
      nicknamPopupShow: false,
      genderPopupShow: false,
      birthdayPopupShow: false,
      newName: null,
      uploader: [],
      actions: [
        { text: "男", value: 1 },
        { text: "女", value: 2 },
        { text: "保密", value: 0 },
      ],
      minDate: new Date(1920, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(1990, 0, 1),
      POSTdata: null,
    };
  },
  computed: {
    User() {
      return this.$store.state.User;
    },
  },
  created() {
    let user = this.User;
    this.newName = user.username;
    //如果有生日，转换格式
    if (user.birthday) this.currentDate = new Date(user.birthday * 1000);
    //组合提交的数据
    this.POSTdata = {
      uid: user.uid,
      username: user.username,
      icon: user.icon,
      gender: user.gender,
      birthday: user.birthday,
    };
  },
  methods: {
    uploadImg(img) {
      let params = new FormData();
      params.append("image", img.file);
      params.append("access_token", localStorage.getItem("token"));
      params.append("source", 1);
      //第三个参数upload是为了让封装过的axios不再序列化params，因为上传不需要序列化
      this.$ajax.post("user/image/upload/", params, "upload").then((res) => {
        this.POSTdata.icon = res.url;
        this.update();
      });
    },
    modifyNickname() {
      this.POSTdata.username = this.newName;
      this.update();
    },
    modifyBirthday(stamp) {
      this.POSTdata.birthday = new Date(stamp).valueOf() / 1000;
      this.update();
    },
    modifyGender(item) {
      this.POSTdata.gender = item.value;
      this.update();
    },
    update() {
      this.$ajax
        .post("user/user/update/", this.POSTdata, "loading")
        .then((res) => {
          this.$store.commit("User", res.data);
          this.$toast.success("修改成功");
          this.nicknamPopupShow = false;
          this.birthdayPopupShow = false;
          this.genderPopupShow = false;
        })
        .catch(() => {
          //只有修改名字会出错，因为是自己输的
          this.POSTdata.username = this.newName = this.User.username;
        });
    },
    save(mod) {
      console.log(mod); //根据mod来判断要修改什么资料，头像/昵称/生日/性别
    },
  },
};
</script>
